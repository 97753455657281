.breadcrumb-item a {
	text-decoration: none;
}

.card,
.dropdown-menu,
.footer,
.header,
.sidebar {
	box-shadow: rgba(50, 50, 93, .02) 0 2px 5px -1px, rgba(0, 0, 0, .05) 0 1px 3px -1px;
}

 html, body {
 	@apply font-sans;
   }